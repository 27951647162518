import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './auth/JwtContext';
// ----------------------------------------------------------------------
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(AuthProvider, { children: _jsx(App, {}) }));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
reportWebVitals();

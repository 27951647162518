import { useCallback, useEffect, useRef } from "react";
export default function useTimeout(callback, delay) {
    const callbackRef = useRef(callback);
    const timeoutRef = useRef({ current: null });
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);
    const set = useCallback(() => {
        timeoutRef.current.current = setTimeout(() => callbackRef.current(), delay);
    }, [delay]);
    const clear = useCallback(() => {
        if (timeoutRef.current.current) {
            clearTimeout(timeoutRef.current.current);
            timeoutRef.current.current = null;
        }
    }, []);
    useEffect(() => {
        set();
        return clear;
    }, [delay, set, clear]);
    const reset = useCallback(() => {
        clear();
        set();
    }, [clear, set]);
    return { reset, clear };
}
